<template>
    <div  style=" background: #f5f5f5;">
      <van-list>
        <van-cell><span style="color: #57c4b7;">*温馨提示：点击可进行升级为健康卡，右滑即可删除副卡。如您发现功能异常或成员信息缺失，请清理微信缓存后重新进入</span></van-cell>
        <div style="background: whitesmoke;">
            <van-cell-group style=" margin: 3% 5%; border-radius:25px;" v-for="item in list" :key="item+'cardm'">
              <van-swipe-cell :before-close="beforeClose" style="border-radius:25px;">
                <!-- <template slot="left">
                    <van-button style="height: 100%;" square type="primary" text="设置" @click="onclickimport(item.idCard,item.isOwner)"/>
                </template> -->
                <van-button class="mainbutton" plain block hairline style="width: 100%; height: auto; " @click="onclicbymaincard(item.userId,item.idCard)">
                  <div style="margin-top: 8px;">
                    <van-image style="float: left; width: 30%; margin-top: 8px; margin-bottom: 18px;" :src="newImg"></van-image>
                    <van-cell v-bind="item" :title="item.userName"  style="float: left; width: 70%; height: auto;"/>
                  </div>
                  <hr style="border: 0.5px solid #d9f2ef; width:100%; margin-bottom: 1px;"/>
                  <van-cell :title="item.phone" v-if="item.isOwner" value="主卡" style="color: #57c4b7;">
                    <van-icon slot="icon" class="iconfont" class-prefix='icon' name="shouji" color="#57c4b7" />
                  </van-cell>
                  <van-cell :title="item.phone" v-else value="副卡" style="color: #57c4b7;">
                    <van-icon slot="icon" class="iconfont" class-prefix='icon' name="shouji" color="#57c4b7" />
                  </van-cell>
                </van-button>
                <template slot="right">
                  <van-button style="height: 100%;" square type="danger" text="删除" @click="onclickdel(item.idCard,item.isOwner)"/>
                </template>
              </van-swipe-cell>
          </van-cell-group>
          <div v-if="!list" style="text-align:center;font-size:15px;padding:10px;">当前没有数据！</div>
          <!-- <van-button round style="width: 90%; height: auto; margin: 3% 5% 8% 5%; background: #57c4b7; color: white;" text="添加成员" @click="onclicaddcard(list)"/> -->
        </div>
      </van-list>
      <div style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;"><span>&copy;拓实银医</span></div>
    </div>
</template>

<script>
import "@/assets/iconfont/phone/iconfont.css";
import { Dialog, Toast } from 'vant';//弹出框
import ajax from '../../lib/ajax';
import store from '../../store';
// let lists = [];

  export default {
    data() {
      return {
        ind: 5,
        // newImg: require('../../assets/imagel/FGcardicon.png'),
        // newImg: require('../../assets/imagel/JCcardicon.png'),
        newImg: require('../../assets/imagel/MDcardicon.png'),
        list: [],
        maincard:''
      }
    },
    created(){
      this.onload();
    },
    methods: {
      async onload() {
        let openid = store.getters["wechat/openid"];
        // alert(openid);
        await ajax.post("/Api/CardService/WeChatGetOwnerMembers?input=" + openid)
        .then((res) => {
          let info = res.data.result;
        this.list=[];
          info.forEach(el => {
            if(el.barCode==""||el.barCode==null){
              this.list.push(el)
            }
          });
          console.log("sss");
          console.log(this.list)
          if(this.list.length==0){
             Dialog.alert({
                  message: '当前，没有可以升级就诊卡！',
                }).then(() => {
                  this.$router.replace("/healcard").catch((err)=>{console.log(err)})
                });
          }
          // this.list = info;
          this.maincard = info.filter(item => item.isOwner == true)[0].idCard;
          store.commit('store/setOwneridcard',this.maincard);
          console.log(this.maincard);
          console.log(this.list);
        }).catch((err) => {
          console.log(err);
          Toast("获取失败，请稍后重试！");
        })
      },
      // position 为关闭时点击的位置
      // instance 为对应的 SwipeCell 实例
      beforeClose({ position, instance }) {
        switch (position) {
          case 'left':
            instance.close();
            break;
          case 'cell':
          case 'outside':
            instance.close();
            break;
          // case 'right':
          //   instance.close();
          //   break;
        }
      },
      showPopup() {
        this.show = true;
      },
      async onclickdel(idCard,isOwner){
        // let first;
        // first = this.maincard;
        if (isOwner) {
          Dialog.alert({
            title:'温馨提示',
            message:'主卡不可删除',
          });
        }else {
          Dialog.confirm({
            title:'温馨提示',
            message:'确认删除'
          }).then(() => {
            ajax.post("/Api/CardService/WeChatDeleteMember?input="+idCard
            // , {
            //   ownerIDCard: first,
            //   deleteMemberIDCard: idCard,
            // }
            ).then((res) => {
              console.log(res);
              this.onload();
              Toast("删除成功");
            }).catch(() => {
              Toast("删除失败，请稍后再试...")
            })
          }).catch(() => {
            // 
          });
        }
        
      },
      onclicbymaincard(userId,idCard) {
        Dialog.confirm({
              title: '提示',
              message: '确认升级为健康卡吗？',
            })
              .then(() => {
                // on confirm
                store.commit('store/setUserId', userId);
               sessionStorage.setItem('showid',idCard); 
               let redirect_uri="http://wx.fgxrmyy.cn/HealCI";
     window.location.href=`https://health.tengmed.com/open/getUserCode?apiVersion=2&redirect_uri=${redirect_uri}`;
              })
              .catch(() => {
                 Toast("您已取消操作");
              });
      },
      onclicaddcard(list) {
        if(list.length<5){
          this.$router.replace({
            path: '/uadd'
          }).catch(err => {
                console.log(err);
            });
        }else{
          Toast("对不起，您的就诊卡绑定达到上限");
        }
      },
      async onclickimport(Idcard,isOwner){
        let mainid;
        mainid = this.maincard;
        if (isOwner) {
          Dialog.alert({
            title:'温馨提示',
            message:'该就诊卡已是主卡',
          });
        } else {
          Dialog.confirm({
            title:'温馨提示',
            message:'确认修改主卡'
          }).then(() => {
            ajax.get("/Api/CardService/WeChatChangeOwner?oldNo="+mainid+"&newNo="+Idcard).then((res) => {
              console.log(res);
              Toast("更换主卡成功");
              this.onload();
            }).catch(() => {
              Toast("更换失败，请稍后再试...")
            })
          }).catch(() => {
            // 
          });
        }
      }
    }
  }
</script>

<style>
  
</style>